#root {
  height: 100%;
}

* {
  scrollbar-color: #adb2bd transparent;
  scrollbar-width: thin;
}

/* customize mac-scrollbar-better css variable */
div.ms-theme-light {
  --ms-track-background: transparent;
  --ms-track-border-color: transparent;
  --ms-thumb-color: #adb2bd;
}
